// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCgJKewqssQQ-KNN8VJKjEcX0AwmYgrVck",
    authDomain: "innovevents-dijon-ca0cd.firebaseapp.com",
    projectId: "innovevents-dijon-ca0cd",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const functions = getFunctions(app);
if (process.env.NODE_ENV == "development") {
    console.log("Functions : DEBUG_MODE");
    connectFunctionsEmulator(functions, "localhost", 5001);
}

export { functions };
