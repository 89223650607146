<template>
    <div class="home">
        <div class="position-relative">
            <img src="../assets/img/home-bg.jpg" class="mw-100 home__img" alt="" />
            <router-link to="/inscription" class="home__btn-register btn btn-primary">
                <span>JE M'INSCRIS</span>
            </router-link>
        </div>

        <NavigationLinks class="mt-5 mb-4" />

        <div class="text-center my-4 arial-rounded home__date">MERCREDI 26 MARS 2025</div>

        <MyCountdown :date="countdown_end" class="my-2" />

        <div class="text-center my-5">
            <div class="fs-4 px-xl-5--5">
                Le succès des deux premières éditions de LADYj. Tech nous conduit cette année à renouveler l'expérience
                dans une troisième édition riche en nouveautés !
            </div>
            <div class="fs-2 my-4 text-center">
                <span class="arial-rounded">Célébrer les Femmes de la Tech :</span>
                <br />
                1 Evénement unique avec 3 séquences, 2 thématiques nouvelles :
                <br />
                <span class="text-green-light">Industrie et International !</span>
            </div>
        </div>

        <!-- >DIJ'INNOV CONNECT -->
        <div class="row home__programme my-5">
            <div class="col-sm-auto col-12 d-flex-center flex-column">
                <span class="home__programme__title-1">DIJ'INNOV</span>
                <span class="home__programme__title-2">CONNECT</span>
            </div>
            <div class="col d-flex align-items-center">
                <div class="bg-green-light w-100 mx-sm-0 mx-5--5 py-sm-0 py-0--5" style="height: 1px"></div>
            </div>
            <div class="col-sm-auto col-12 d-flex-center">
                <span class="mb-4 fs-2">De 14h à 15h30</span>
            </div>
            <div class="col-12">
                <div class="fs-4 border-start ps-4 border-green-light border-3">
                    <div class="row">
                        <div class="col-12">
                            <p>
                                <span class="text">
                                    La séquence 100% dédiée à la rencontre des Laboratoires de Recherche- Etudiants et
                                    Etudiantes de masters et masteres, des doctorants, doctorantes et les entreprises
                                </span>
                            </p>
                            <p>En un seul lieu, un temps privilégié et de belles opportunités pour retrouver :</p>
                            <p class="d-flex gap-4 align-items-baseline">
                                <i class="fa-solid fa-arrow-right text-green-light"></i> Les pitchs des étudiantes
                            </p>
                            <p class="d-flex gap-4 align-items-baseline">
                                <i class="fa-solid fa-arrow-right text-green-light"></i>
                                <span>
                                    Des mini- stands ouverts d'échanges entre chercheurs et entreprises et étudiants sur
                                    les sujets déposés sur ce site par les laboratoires, les problématiques de R&D des
                                    entreprises
                                </span>
                            </p>
                            <p class="d-flex gap-4 align-items-baseline">
                                <i class="fa-solid fa-arrow-right text-green-light"></i>
                                <span>
                                    Une présentation des dispositifs d'accompagnement du Conseil régional (Itinéraire
                                    Chercheur Entrepreneur), de l' Etat (Conventions Industrielles de Formation par la
                                    Recherche) et de l'Europe (Women in Tech,..)
                                </span>
                            </p>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-stretch gap-4 flex-wrap">
                            <router-link
                                to="/inscription"
                                class="btn btn-lg my-3 px-4 py-2--5 fs-6 btn-primary home__programme__action"
                            >
                                JE M'INSCRIS
                            </router-link>
                            <router-link
                                to="/opportunites-laboratoires"
                                class="btn btn-lg my-3 px-4 py-2--5 fs-6 btn-green-light home__programme__action"
                            >
                                DÉCOUVRIR LES OPPORTUNITÉS
                                <br />
                                DES LABORATOIRES
                            </router-link>
                            <router-link
                                to="/depot-problematique"
                                class="btn btn-lg my-3 px-4 py-2--5 fs-6 btn-green-dark home__programme__action"
                            >
                                ENTREPRISES, DÉPOSEZ
                                <br />
                                VOS EXPRESSIONS DE BESOINS
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--  La féminisation des métiers de l'Industrie -->
        <div class="row home__programme my-5">
            <div class="col-lg-auto col-12 d-flex-center flex-column">
                <span class="home__programme__title-1"> La féminisation des</span>
                <span class="home__programme__title-2">métiers de l'Industrie</span>
            </div>
            <div class="col d-flex align-items-center">
                <div class="bg-primary w-100 mx-lg-0 mx-5--5 py-lg-0 py-0--5" style="height: 1px"></div>
            </div>
            <div class="col-lg-auto col-12 d-flex-center">
                <span class="mb-4 fs-2">De 15h30 à 17h</span>
            </div>
            <div class="col-12">
                <div class="fs-4 border-start ps-4 border-primary border-3">
                    <div class="row">
                        <div class="col-12">
                            <p>
                                Séquence pilotée par Bourgogne Industrie qui regroupe le Territoire d'Industrie, label
                                national, comportant Dijon Métropole, la Communauté Urbaine Creusot-Montceau, le Grand
                                Charolais, le Grand Autunois Morvan et la Communauté de Communes Entre Arroux Somme et
                                Loire.
                            </p>
                            <p>
                                Seront évoqués l'attractivité des métiers de l'industrie, l'évolution des carrières mais
                                aussi des exemples de dispositifs à succès avec des témoignages inspirants de Femmes qui
                                innovent dans l'industrie !
                            </p>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-stretch gap-4">
                            <router-link
                                to="/inscription"
                                class="btn btn-lg my-3 px-4 py-2--5 fs-6 btn-primary home__programme__action"
                            >
                                JE M'INSCRIS
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Tables rondes -->
        <div class="row home__programme my-5">
            <div class="col-sm-auto col-12 d-flex-center flex-column">
                <span class="home__programme__title-1">TABLES</span>
                <span class="home__programme__title-2">RONDES</span>
            </div>
            <div class="col d-flex align-items-center">
                <div class="bg-orange w-100 mx-sm-0 mx-5--5 py-sm-0 py-0--5" style="height: 1px"></div>
            </div>
            <div class="col-sm-auto col-12 d-flex-center">
                <span class="mb-4 fs-2">De 17h à 19h30</span>
            </div>
            <div class="col-12">
                <div class="fs-4 border-start ps-4 border-orange border-3">
                    <div class="row">
                        <div class="col-12">
                            <p class="fw-bold">
                                Vous qui êtes dirigeants et dirigeantes d'entreprises, universitaires, chercheurs et
                                chercheures, étudiants et étudiantes, acteurs de l'innovation… Participez nombreux à cet
                                éclairage international !
                            </p>
                            <p>
                                La 1ère table-ronde met en lumière des dirigeantes de startups régionales
                                multi-sectorielles qui exportent ou lèvent des fonds à l'international et de startups
                                étrangères
                            </p>
                            <p>
                                La 2ème table-ronde est consacrée à la double thématique international/ Industrie et
                                services à l'industrie avec les témoignages de dirigeantes de startups industrielles et
                                de grandes entreprises.
                            </p>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-stretch gap-4">
                            <router-link
                                to="/inscription"
                                class="btn btn-lg px-4 py-2--5 fs-6 btn-primary home__programme__action"
                            >
                                JE M'INSCRIS
                            </router-link>
                            <button
                                @click="openProgramPdf"
                                class="btn btn-lg px-4 py-2--5 fs-6 btn-orange home__programme__action"
                            >
                                VOIR LE PROGRAMME
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column align-items-center text-center my-5--5 px-3 px-md-5 px-xl-5--5">
            <span class="home__programme__title-2 mt-0">COCKTAIL</span>
            <div class="separation bg-blue"></div>
            <span class="mt-2 mb-3 fs-2">De 19H30 à 21H</span>
            <span class="fs-4">Venez profiter d'un moment d'échange et de partage</span>
        </div>
    </div>
</template>

<script>
import MyCountdown from "@/components/MyCountdown.vue";
import NavigationLinks from "@/components/NavigationLinks.vue";

export default {
    components: {
        MyCountdown,
        NavigationLinks,
    },
    data() {
        return {
            countdown_end: new Date("2025-03-26T14:00:00"),
        };
    },
    methods: {
        openProgramPdf() {
            window.open("/resources/PROGRAMME LADYj.Tech 2.pdf", "_blank");
        },
    },
};
</script>
