import { render, staticRenderFns } from "./TooSoonView.vue?vue&type=template&id=28520c26&scoped=true&"
var script = {}
import style0 from "./TooSoonView.vue?vue&type=style&index=0&id=28520c26&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28520c26",
  null
  
)

export default component.exports